import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Col,
  Modal,
  Form,
  Row,
  Image,
  Spin,
  TableColumnsType,
  Table,
  Select,
  TablePaginationConfig,
  Tooltip,
  Alert,
} from 'antd';

import { PageOptionsModel, PageOrder } from '@app/domain/PageMetaModel';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { PageTitle } from '../common/PageTitle/PageTitle';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import moment from 'moment';
import {
  GroupOrder,
  GroupOrderDisplaySetting,
  GroupResponse,
  getGroupOrders,
  updateGroupOrderDisplaySetting,
  updateGroupOrderDisplaySettingRequest,
} from '../../api/group.api';
import Search from 'antd/lib/input/Search';
import {
  CommissionRateRequest,
  EnableSettingRequest,
  IDENTITY_STATUS,
  SellerResponse,
  SellersResponse,
  enableCreditCart,
  getSellers,
  upsertCommissionRate,
  UpdateIdentityStatus,
  UpdateIdentityStatusRequest,
} from '../../api/seller.api';

const initialPagination: PageOptionsModel = {
  current: 1,
  pageSize: 10,
};
const initialEnableSettingRequest: EnableSettingRequest = {
  enable: false,
};
const initialCommissionRateRequest: CommissionRateRequest = {
  rate: 0,
};
const initialUpdateIdentityStatusRequest: UpdateIdentityStatusRequest = {
  identity_status: 0,
};
const shopUrl = process.env.REACT_APP_SHOP_URL;

export const Seller: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: SellerResponse[];
    pagination: PageOptionsModel;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const [isEnableCreditCardModalOpen, setIsEnableCreditCardModalOpen] = useState<boolean>(false);
  const [EnableCreditCartData, setEnableCreditCartData] = useState<EnableSettingRequest>(initialEnableSettingRequest);
  const [isCommissionRateModalOpen, setIsCommissionRateModalOpen] = useState<boolean>(false);
  const [commissionRateData, setCommissionRateData] = useState<CommissionRateRequest>(initialCommissionRateRequest);
  const [updateIdentityStatusData, setUpdateIdentityStatusData] = useState<UpdateIdentityStatusRequest>(
    initialUpdateIdentityStatusRequest,
  );
  const [updateSellerId, setUpdateSellerId] = useState<string>('');
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [formCommission] = Form.useForm();

  const fetch = useCallback(
    (pagination: PageOptionsModel) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getSellers({ params: pagination })
        .then((res) => {
          if (isMounted.current) {
            const newPagination: TablePaginationConfig & { search?: string } = {
              ...res.meta,
              showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
              // search: pagination.search,
            };
            if (pagination.search) {
              newPagination.search = pagination.search;
            }
            setTableData({ data: res.data, pagination: newPagination, loading: false });
          }
        })
        .finally(() => {
          return setTableData((tableData) => ({ ...tableData, loading: false }));
        });
    },
    [isMounted],
  );
  useEffect(() => {
    getSellers({ params: tableData.pagination })
      .then((res) => {
        const pagination = {
          ...res.meta,
          showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true,
        };
        setTableData({ data: res.data, pagination: pagination, loading: false });
      })
      .finally(() => {
        return setTableData((tableData) => ({ ...tableData, loading: false }));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.debug('tableData.pagination', tableData.pagination);
  // }, [tableData.pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SellerResponse> | SorterResult<SellerResponse>[],
  ) => {
    const pagination: PageOptionsModel = {
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
    };
    if ('order' in sorter && sorter.order == 'ascend') {
      pagination.sortColumn = sorter.columnKey?.toString();
      pagination.sortOrder = PageOrder.ASC;
    } else if ('order' in sorter && sorter.order == 'descend') {
      pagination.sortColumn = sorter.columnKey?.toString();
      pagination.sortOrder = PageOrder.DESC;
    }
    if (tableData.pagination.search) {
      pagination.search = tableData.pagination.search;
    }
    if (
      tablePagination.current != tableData.pagination.current ||
      tablePagination.pageSize != tableData.pagination.pageSize ||
      Object.keys(sorter).length > 0
    ) {
      fetch(pagination);
    }
  };

  const handleEnableCreditCard = async () => {
    setLoading(true);
    try {
      await enableCreditCart(updateSellerId, EnableCreditCartData);
      notificationController.success({
        message: 'Success',
        description: `Enabled Credit Card `,
      });
      setIsEnableCreditCardModalOpen(false);
      setUpdateSellerId('');
      fetch(tableData.pagination);
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Fail',
        description: getErrorMessage(error),
      });
      setLoading(false);
    }
  };
  const handleUpdateIdentityStatus = async () => {
    setLoading(true);
    try {
      await UpdateIdentityStatus(updateSellerId, updateIdentityStatusData);
      notificationController.success({
        message: 'Success',
        description: `Updated Identity Status`,
      });
      setIsEnableCreditCardModalOpen(false);
      setUpdateSellerId('');
      fetch(tableData.pagination);
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Fail',
        description: getErrorMessage(error),
      });
      setLoading(false);
    }
  };
  const handleCommissionRate = async () => {
    formCommission.validateFields().then(async () => {
      try {
        setLoading(true);
        await upsertCommissionRate(updateSellerId, commissionRateData);
        notificationController.success({
          message: 'Success',
          description: `Set commission rate to ${commissionRateData.rate}`,
        });
        setCommissionRateData(initialCommissionRateRequest);
        setUpdateSellerId('');
        fetch(tableData.pagination);
        setLoading(false);
      } catch (error) {
        notificationController.error({
          message: 'Fail',
          description: getErrorMessage(error),
        });
        setLoading(false);
      }
      setIsCommissionRateModalOpen(false);
    });
  };

  const onSearch = (value: string) => {
    const pagination: PageOptionsModel = {
      ...tableData.pagination,
      current: 1,
    };
    if (value === '') {
      delete pagination.search;
    } else {
      pagination.search = value;
    }
    setTableData((tableData) => ({ data: tableData.data, pagination: pagination, loading: true }));
    fetch(pagination);
  };

  const columns: TableColumnsType<SellerResponse> = [
    {
      title: 'ID',
      key: 'id',
      // sorter: true,
      render: (text, record) => (
        <Tooltip title={record.id}>
          <div
            style={{
              width: 100, // 设置你需要的最大宽度
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <a href={shopUrl + '/c/s/0/creator/' + record.id} target="_blank" rel="noreferrer">
              {record.id}
            </a>
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      render: (text, record) => (
        <Tooltip title={record.email}>
          <div
            style={{
              width: 150, // 设置你需要的最大宽度
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {record.email}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Name',
      key: 'display_name',
      render: (text, record) => (
        <Tooltip title={record.display_name}>
          <div
            style={{
              width: 150, // 设置你需要的最大宽度
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <a href={shopUrl + '/c/s/0/creator/' + record.id} target="_blank" rel="noreferrer">
              {record.display_name}
            </a>
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text, record) => record.phone,
    },
    {
      title: 'Credit Card & Payout',
      key: 'creditCardPayout',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setUpdateSellerId(record.id);
              setIsEnableCreditCardModalOpen(true);
              const isEnableCreditCard =
                record.identity_status ===
                IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED_TEMP_ALLOW_CREDIT_CARD;
              setEnableCreditCartData({ enable: isEnableCreditCard });
              setUpdateIdentityStatusData({ identity_status: record.identity_status });
              form.setFieldsValue({ setting: record.identity_status });
            }}
          >
            {/* 
              @zhenkai
              信用卡和payout都关闭：3
              信用卡打开，payout关闭：4
              信用卡和payout都打开：10
              信用卡关闭，payout打开：没有这种情况 

              [0, 2, 3]:信用卡和payout都关闭
              [4]:信用卡打开，payout关闭
              [null, 1, 10, 11]:信用卡和payout都打开
              信用卡关闭，payout打开 没有这种情况
              */}
            {/* {record.identity_status ===
              IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED_TEMP_ALLOW_CREDIT_CARD && (
              <Tooltip title="Credit Card Enabled, Payout Disabled">CCE, PD</Tooltip>
            )}
            {record.identity_status === IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED && (
              <Tooltip title="Credit Card Disabled, Payout Disabled">CCD, PD</Tooltip>
            )}
            {record.identity_status === IDENTITY_STATUS.NO_IDENTITY_VERIFIED_BUT_STRIPE_VERIFIED && (
              <Tooltip title="Credit Card Enabled, Payout Enabled">CCE, PE</Tooltip>
            )} */}
            {record.identity_status ===
            IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED_TEMP_ALLOW_CREDIT_CARD ? (
              <Tooltip title="Credit Card Enabled, Payout Disabled">CCE, PD</Tooltip>
            ) : record.identity_status != null &&
              [
                IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED,
                IDENTITY_STATUS.IDENTITY_UNVERIFIED,
                IDENTITY_STATUS.SUSPECTED_SELLER,
              ].includes(record.identity_status) ? (
              <Tooltip title="Credit Card Disabled, Payout Disabled">CCD, PD</Tooltip>
            ) : [
                null,
                IDENTITY_STATUS.IDENTITY_VERIFIED,
                IDENTITY_STATUS.NO_IDENTITY_VERIFIED_BUT_STRIPE_VERIFIED,
                IDENTITY_STATUS.IDENTITY_VERIFIED_AND_STRIPE_VERIFIED,
              ].includes(record.identity_status) ? (
              <Tooltip title="Credit Card Enabled, Payout Enabled">CCE, PE</Tooltip>
            ) : (
              'Unknown'
            )}
          </Button>
        );
      },
    },
    {
      title: (
        <Tooltip title="Commission Rate">
          <span>Commission</span>
        </Tooltip>
      ),
      key: 'commission',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setUpdateSellerId(record.id);
              setIsCommissionRateModalOpen(true);
              setCommissionRateData({ rate: record.commission_rate });
              formCommission.setFieldsValue({ commissionRate: record.commission_rate });
            }}
          >
            {record.commission_rate}
          </Button>
        );
      },
    },
    {
      title: 'Category',
      key: 'category',
      render: (text, record) => record.category,
    },

    {
      title: (
        <Tooltip placement="bottom" title="Sort data on the current page only">
          <span>Transaction</span>
        </Tooltip>
      ),
      key: 'transaction_count',
      render: (text, record) => record.transaction_count,
      sorter: (a, b) => a.transaction_count - b.transaction_count,
    },
    {
      title: t('common.createdAt'),
      key: 'created_at',
      render: (text, record) => moment(record.created_at).format('LLL'),
      sorter: true,
    },
    {
      title: t('common.updatedAt'),
      key: 'updated_at',
      render: (text, record) => moment(record.updated_at).format('LLL'),
      sorter: true,
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (text, record) => (
    //     <Button
    //       type="link"
    //       onClick={() => {
    //         setIsUpdatePromoTagsModalOpen(true);
    //       }}
    //     >
    //       Edit
    //     </Button>
    //   ),
    // },
  ];

  return (
    <>
      <Search
        placeholder="id, name, email, category"
        allowClear
        onSearch={onSearch}
        style={{ width: 300 }}
        size="small"
      />
      <PageTitle>Seller</PageTitle>
      <Table
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
        // rowClassName="editable-row"
        tableLayout="auto"
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        // scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title={'Update: Identity Status'}
        centered
        open={isEnableCreditCardModalOpen}
        onOk={() => handleUpdateIdentityStatus()}
        onCancel={() => {
          setLoading(false);
          setIsEnableCreditCardModalOpen(false);
          setEnableCreditCartData(initialEnableSettingRequest);
          setUpdateSellerId('');
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading}>
          <Form name="basic" form={form}>
            <Form.Item label={'Setting'} name="setting" rules={[{ required: true, message: 'Please select setting!' }]}>
              <Select
                defaultValue={updateIdentityStatusData.identity_status}
                style={{ width: 370 }}
                onChange={(value: number) => setUpdateIdentityStatusData({ identity_status: value })}
                options={[
                  {
                    value: IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED,
                    label: 'Disable Credit Card and Payout',
                  },
                  {
                    value: IDENTITY_STATUS.NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED_TEMP_ALLOW_CREDIT_CARD,
                    label: 'Enable Credit Card and Disable Payout ',
                  },
                  {
                    value: IDENTITY_STATUS.NO_IDENTITY_VERIFIED_BUT_STRIPE_VERIFIED,
                    label: 'Enable Credit Card and Payout ',
                  },
                ]}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={'Commission Rate'}
        centered
        open={isCommissionRateModalOpen}
        onOk={() => handleCommissionRate()}
        onCancel={() => {
          setLoading(false);
          setIsCommissionRateModalOpen(false);
          setUpdateSellerId('');
        }}
        confirmLoading={loading}
      >
        <Alert
          message="This will change the commission settings for all stores under the merchant, unless a specific store has a separate commission rate set."
          type="warning"
        />
        <Spin spinning={loading}>
          <Form name="basic" form={formCommission}>
            <Form.Item
              label={'Commission Rate'}
              name="commissionRate"
              rules={[
                { required: true, message: 'Please select setting!' },
                {
                  validator: (_, value) => {
                    if (value >= 0 && value < 1) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The value must be greater than or equal to 0 and less than 1!'));
                  },
                },
              ]}
            >
              <Input
                placeholder="Input commission rate"
                value={commissionRateData.rate}
                onChange={(e) => setCommissionRateData({ rate: parseFloat(e.target.value) })}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
