import { httpApi } from '@app/api/http.api';
import { number } from 'echarts';
export interface Statistic {
  id: number;
  value: number;
  prevValue: number;
  unit: 'kg';
}

export interface LangCount {
  en?: number;
  zh?: number;
  zht?: number;
  es?: number;
}

export interface DeviceTypeCount {
  ios?: number;
  android?: number;
}

export interface AppCustomersResponse {
  date: string;
  langCount: LangCount;
  deviceTypeCount: DeviceTypeCount;
}

export interface AppActiveCustomersResponse {
  date: string;
  number: number;
}

export interface AppFreqActiveCustomersResponse {
  result: ActiveCustomerStats[];
  total_customers: number;
}

export interface ActiveCustomerStats {
  date: string;
  total: number;
  web: number;
  app: number;
}

export interface AppReferralStatsResponse {
  date: string;
  referral_total: number;
}

export interface AppFormDataNewAppCustomersResponse {
  created_at: string;
  customer_id: string;
  lang: string;
  device_type: string;
}

export interface AppFormDataActiveCustomersResponse {
  id: number;
  customer_id: string;
  group_id: number;
  zipcode: string;
  city: string;
  group_name: string;
  promotion_code: string;
  created_at: string;
}

export interface ReferralFormDataResponse {
  referrer_id: string;
  referee_id: string;
  created_at: string;
}

export const getStatistics = (): Promise<Statistic[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          id: 1,
          value: 45,
          prevValue: 30,
          unit: 'kg',
        },
        {
          id: 2,
          value: 12,
          prevValue: 20,
          unit: 'kg',
        },
        {
          id: 3,
          value: 90,
          prevValue: 60,
          unit: 'kg',
        },
        {
          id: 4,
          value: 78,
          prevValue: 90,
          unit: 'kg',
        },
      ]);
    }, 0);
  });
};

export const getAppCustomers = (startDate: string, endDate: string): Promise<AppCustomersResponse[]> =>
  httpApi
    .get<AppCustomersResponse[]>(`v1/admin/stats/new-app-customers?startDate=${startDate}&endDate=${endDate}`)
    .then(({ data }) => {
      return data as AppCustomersResponse[];
    });

export const getActiveCustomers = (startDate: string, endDate: string): Promise<AppActiveCustomersResponse[]> =>
  httpApi
    .get<AppActiveCustomersResponse[]>(`v1/admin/stats/active-customers?startDate=${startDate}&endDate=${endDate}`)
    .then(({ data }) => {
      return data as AppActiveCustomersResponse[];
    });

export const getFrequentCustomers = (
  startDate: string,
  endDate: string,
  placedAmount: number,
): Promise<AppFreqActiveCustomersResponse> =>
  httpApi
    .get<AppFreqActiveCustomersResponse>(
      `v1/admin/stats/frequent-customers?startDate=${startDate}&endDate=${endDate}&placedAmount=${placedAmount}`,
    )
    .then(({ data }) => {
      return data as AppFreqActiveCustomersResponse;
    });

export const getReferralStats = (startDate: string, endDate: string): Promise<AppReferralStatsResponse[]> =>
  httpApi
    .get<AppReferralStatsResponse[]>(`v1/admin/stats/referral-customers?startDate=${startDate}&endDate=${endDate}`)
    .then(({ data }) => {
      return data as AppReferralStatsResponse[];
    });

export const getFormDataNewAppCustomers = (
  startDate: string,
  endDate: string,
): Promise<AppFormDataNewAppCustomersResponse[]> =>
  httpApi
    .get<AppFormDataNewAppCustomersResponse[]>(
      `v1/admin/stats/form-data-new-users?startDate=${startDate}&endDate=${endDate}`,
    )
    .then(({ data }) => {
      return data as AppFormDataNewAppCustomersResponse[];
    });

export const getFormDataActiveCustomers = (
  startDate: string,
  endDate: string,
): Promise<AppFormDataActiveCustomersResponse[]> =>
  httpApi
    .get<AppFormDataActiveCustomersResponse[]>(
      `v1/admin/stats/form-data-active-customers?startDate=${startDate}&endDate=${endDate}`,
    )
    .then(({ data }) => {
      return data as AppFormDataActiveCustomersResponse[];
    });

export const getFormDataFreqActiveCustomers = (
  startDate: string,
  endDate: string,
  placedAmount: number,
): Promise<AppFormDataActiveCustomersResponse[]> =>
  httpApi
    .get<AppFormDataActiveCustomersResponse[]>(
      `v1/admin/stats/form-data-freq-active-customers?startDate=${startDate}&endDate=${endDate}&placedAmount=${placedAmount}`,
    )
    .then(({ data }) => {
      return data as AppFormDataActiveCustomersResponse[];
    });

export const getReferralFormData = (startDate: string, endDate: string): Promise<ReferralFormDataResponse[]> =>
  httpApi
    .get<ReferralFormDataResponse[]>(`v1/admin/stats/form-data-referral?startDate=${startDate}&endDate=${endDate}`)
    .then(({ data }) => {
      return data as ReferralFormDataResponse[];
    });
